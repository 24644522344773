import {
  format,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
} from "date-fns";
import { TypographyH3 } from "../typography";
import { CalendarEvent } from "./types";
import { useState } from "react";
import { Button } from "../ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Book, Linkedin } from "lucide-react";
import { Text } from "@radix-ui/themes";

export interface CalendarMonthComponentProps {
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
}

type EventTypeInfo = {
  label: string;
  icon: JSX.Element;
  colors: {
    planned: string;
    draft: string;
  };
};

const EVENT_TYPES: Record<CalendarEvent["type"], EventTypeInfo> = {
  blog_post: {
    label: "Blog Post",
    icon: <Book className="w-4 h-4" />,
    colors: {
      planned: "from-emerald-800 to-emerald-900",
      draft: "from-emerald-400 to-emerald-300",
    },
  },
  linkedin_post: {
    label: "LinkedIn Post",
    icon: <Linkedin className="w-4 h-4" />,
    colors: {
      planned: "from-blue-600 to-blue-700",
      draft: "from-blue-200 to-blue-300",
    },
  },
  x_post: {
    label: "X Post",
    icon: (
      <svg
        viewBox="0 0 24 24"
        className="w-4 h-4"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
      </svg>
    ),
    colors: {
      planned: "from-purple-600 to-purple-700",
      draft: "from-purple-200 to-purple-300",
    },
  },
};

export const CalendarMonthComponent = ({
  events,
  onEventClick,
}: CalendarMonthComponentProps): JSX.Element => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState<"month" | "week">("month");

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const firstDayOfWeek = startOfWeek(currentDate);
  const lastDayOfWeek = endOfWeek(currentDate);

  const daysInMonth = lastDayOfMonth.getDate();
  const startingDayOfWeek = firstDayOfMonth.getDay();

  const goToPreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const goToPreviousWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1));
  };

  const goToNextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };

  const getEventIcon = (type: CalendarEvent["type"]) => {
    return EVENT_TYPES[type].icon;
  };

  const getEventColor = (
    status: CalendarEvent["status"],
    type: CalendarEvent["type"]
  ) => {
    return EVENT_TYPES[type].colors[status];
  };

  const days = [];

  if (viewMode === "month") {
    for (let i = 0; i < startingDayOfWeek; i++) {
      days.push(
        <div
          key={`empty-${i}`}
          className="h-24 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg opacity-50"
        />
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );
      const dayEvents = events.filter(
        (event) => event.date.toDateString() === date.toDateString()
      );

      const isToday = date.toDateString() === new Date().toDateString();

      days.push(
        <div
          key={day}
          className={`h-24 p-2 rounded-lg transition-all duration-200 hover:transform hover:scale-[1.02] ${
            isToday
              ? "bg-gradient-to-br from-blue-50 to-indigo-50 shadow-lg border-2 border-blue-200"
              : "bg-white shadow-sm hover:shadow-md border border-gray-100"
          }`}
        >
          <div className="flex justify-between items-center">
            <span
              className={`font-semibold ${
                isToday
                  ? "text-blue-600 bg-blue-100 rounded-full w-6 h-6 flex items-center justify-center text-sm"
                  : "text-gray-700"
              }`}
            >
              {day}
            </span>
            {dayEvents.length > 0 && (
              <span className="flex h-2 w-2 relative">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
              </span>
            )}
          </div>
          <div className="mt-1 space-y-1 max-h-14 overflow-y-auto">
            {dayEvents.map((event) => (
              <TooltipProvider key={event.id}>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <div
                      className={`w-full py-1.5 px-2 rounded-md cursor-pointer
                        text-white bg-gradient-to-r
                        hover:brightness-110 transition-all
                        shadow-sm hover:shadow flex items-center justify-between
                        ${getEventColor(event.status, event.type)}`}
                      onClick={() => onEventClick(event)}
                    >
                      <div className="flex items-center gap-2 min-w-0">
                        {getEventIcon(event.type)}
                      </div>
                      <div className="text-xs whitespace-nowrap ml-1 opacity-90">
                        {format(event.date, "h:mm a")}
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="font-medium">{event.title}</div>
                    <div className="text-xs opacity-75">
                      {format(event.date, "PPp")} • {event.status}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>
      );
    }
  } else {
    // Week view with larger cells and more event details
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      const dayEvents = events.filter(
        (event) => event.date.toDateString() === date.toDateString()
      );

      const isToday = date.toDateString() === new Date().toDateString();

      days.push(
        <div
          key={`week-${i}`}
          className={`h-96 p-4 rounded-lg transition-all duration-200 hover:transform hover:scale-[1.02] ${
            isToday
              ? "bg-gradient-to-br from-blue-50 to-indigo-50 shadow-lg border-2 border-blue-200"
              : "bg-white shadow-sm hover:shadow-md border border-gray-100"
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <div className="flex flex-col">
              <span
                className={`text-xl font-semibold ${
                  isToday
                    ? "text-blue-600 bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center"
                    : "text-gray-700 rounded-full w-10 h-10 flex items-center justify-center"
                }`}
              >
                {date.getDate()}
              </span>
            </div>
            {dayEvents.length > 0 && (
              <span className="flex h-3 w-3 relative">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
              </span>
            )}
          </div>
          <div className="space-y-2 overflow-y-auto h-72">
            {dayEvents.map((event) => (
              <TooltipProvider key={event.id}>
                <Tooltip>
                  <TooltipTrigger className="w-full">
                    <div
                      className={`w-full p-4 rounded-md cursor-pointer transition-all
                        bg-gradient-to-r shadow-sm hover:shadow hover:brightness-110
                        text-white min-h-[100px] flex flex-col justify-between items-center
                        ${getEventColor(event.status, event.type)}`}
                      onClick={() => onEventClick(event)}
                    >
                      <div className="flex items-center gap-2 mb-2">
                        {getEventIcon(event.type)}
                      </div>
                      <div className="text-sm opacity-90">
                        {format(event.date, "h:mm a")}
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="font-medium">{event.title}</div>
                    <div className="text-xs opacity-75">
                      {format(event.date, "PPp")} • {event.status}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col gap-4 w-full h-full bg-white rounded-xl overflow-auto">
      <div className="flex justify-between items-center sticky top-0 bg-white z-10 p-2">
        <div className="flex items-center gap-4">
          <TypographyH3>
            {viewMode === "month"
              ? format(firstDayOfMonth, "MMMM yyyy")
              : `${format(firstDayOfWeek, "MMM d")} - ${format(
                  lastDayOfWeek,
                  "MMM d, yyyy"
                )}`}
          </TypographyH3>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            onClick={
              viewMode === "month" ? goToPreviousMonth : goToPreviousWeek
            }
          >
            <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
          </Button>
          <Button
            variant="outline"
            onClick={viewMode === "month" ? goToNextMonth : goToNextWeek}
          >
            <ChevronRightIcon className="w-5 h-5 text-gray-600" />
          </Button>
          <Select
            value={viewMode}
            onValueChange={(value: "month" | "week") => setViewMode(value)}
          >
            <SelectTrigger className="w-32">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="month">Month</SelectItem>
              <SelectItem value="week">Week</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-2 px-2">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className="h-8 flex items-center justify-center font-medium text-gray-400 text-sm tracking-wider sticky top-16 bg-white z-10"
          >
            {day}
          </div>
        ))}
        {days}
      </div>

      <div className="mt-8 border-t border-gray-100 pt-6 pb-4 px-6">
        <div className="flex items-center justify-center gap-6">
          {Object.entries(EVENT_TYPES).map(([type, info]) => (
            <div
              key={type}
              className="shadow-lg hover:shadow-xl transition-shadow duration-300 
                        bg-gradient-to-br from-white to-gray-50 rounded-lg p-4 
                        border border-gray-100"
            >
              <div className="flex items-center gap-4">
                <div
                  className="w-12 h-12 flex items-center justify-center 
                              bg-gradient-to-br from-blue-100 to-purple-100 
                              rounded-lg shadow-md"
                >
                  <div className="text-gray-700">{info.icon}</div>
                </div>

                <div className="flex flex-col gap-3">
                  <Text size="4" weight="bold">
                    {info.label}
                  </Text>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <div
                        className={`w-8 h-2.5 rounded-full bg-gradient-to-r shadow-sm ${info.colors.planned}`}
                      />
                      <span className="text-xs text-gray-600">Planned</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <div
                        className={`w-8 h-2.5 rounded-full bg-gradient-to-r shadow-sm ${info.colors.draft}`}
                      />
                      <span className="text-xs text-gray-600">Draft</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
