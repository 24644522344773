import { addDays, isAfter, isToday, isTomorrow } from "date-fns";
import { CalendarEvent } from "./types";
import { TypographyH4 } from "../typography";

export interface CalendarUpcomingComponentProps {
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
}

export const CalendarUpcomingComponent = ({
  events,
  onEventClick,
}: CalendarUpcomingComponentProps): JSX.Element => {
  const today = events.filter((event) => isToday(event.date));
  const upcoming = events.filter((event) => isTomorrow(event.date));
  const later = events.filter((event) =>
    isAfter(event.date, addDays(new Date(), 2))
  );

  return (
    <div className="flex flex-col gap-4 overflow-y-auto px-2 pb-8">
      <div className="flex flex-col gap-4">
        <TypographyH4>Today</TypographyH4>
        {today.map((event) => (
          <div
            key={event.id}
            className="shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer overflow-hidden border-radius-md bg-gradient-to-br from-white to-gray-50 p-4 rounded-lg border border-gray-100"
            onClick={() => onEventClick(event)}
          >
            <div className="flex">
              <div className="w-1/4 flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100 rounded-lg h-24">
                {event.thumbnail ? (
                  <img
                    src={event.thumbnail}
                    alt={event.title}
                    className="w-full h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full h-24 flex items-center justify-center text-gray-400">
                    <svg
                      className="w-8 h-8"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className="w-3/4 pl-4 flex flex-col justify-center">
                <div className="text-sm font-medium text-gray-500">
                  {event.date.toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </div>
                <div className="font-semibold text-gray-900 line-clamp-2 mt-1">
                  {event.title}
                </div>
              </div>
            </div>
          </div>
        ))}
        {today.length === 0 && <div>No events today</div>}
      </div>
      <div className="flex flex-col gap-4">
        <TypographyH4>Tomorrow</TypographyH4>
        {upcoming.map((event) => (
          <div
            key={event.id}
            className="shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer overflow-hidden border-radius-md bg-gradient-to-br from-white to-gray-50 p-4 rounded-lg border border-gray-100"
            onClick={() => onEventClick(event)}
          >
            <div className="flex">
              <div className="w-1/4 flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100 rounded-lg h-24">
                {event.thumbnail ? (
                  <img
                    src={event.thumbnail}
                    alt={event.title}
                    className="w-full h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full h-24 flex items-center justify-center text-gray-400">
                    <svg
                      className="w-8 h-8"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className="w-3/4 pl-4 flex flex-col justify-center">
                <div className="text-sm font-medium text-gray-500">
                  {event.date.toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </div>
                <div className="font-semibold text-gray-900 line-clamp-2 mt-1">
                  {event.title}
                </div>
              </div>
            </div>
          </div>
        ))}
        {upcoming.length === 0 && <div>No events tomorrow</div>}
      </div>
      <div className="flex flex-col gap-4">
        <TypographyH4>This Week</TypographyH4>
        {later.map((event) => (
          <div
            key={event.id}
            className="shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer overflow-hidden border-radius-md bg-gradient-to-br from-white to-gray-50 p-4 rounded-lg border border-gray-100"
            onClick={() => onEventClick(event)}
          >
            <div className="flex">
              <div className="w-1/4 flex items-center justify-center bg-gradient-to-br from-blue-100 to-purple-100 rounded-lg h-24">
                {event.thumbnail ? (
                  <img
                    src={event.thumbnail}
                    alt={event.title}
                    className="w-full h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full h-24 flex items-center justify-center text-gray-400">
                    <svg
                      className="w-8 h-8"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className="w-3/4 pl-4 flex flex-col justify-center">
                <div className="text-sm font-medium text-gray-500">
                  {event.date.toLocaleDateString("en-US", {
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                  })}
                </div>
                <div className="font-semibold text-gray-900 line-clamp-2 mt-1">
                  {event.title}
                </div>
              </div>
            </div>
          </div>
        ))}
        {events.length > 0 && later.length === 0 && (
          <div>No more events this week</div>
        )}
        {events.length === 0 && <div>No events this week</div>}
      </div>
    </div>
  );
};
