import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useEffect } from "react";
import { Flex } from "@radix-ui/themes";
import {
  useBrandContext,
  useLinkedinDisconnect,
  useLinkedinLink,
  useLinkedinStatus,
  useXLink,
  useXStatus,
} from "@/hooks";

import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { Integration } from "@/components/integration";
import { useGetBrandsQuery } from "@/redux";
import { useXDisconnect } from "../../../hooks";

export const Integrations = (): JSX.Element => {
  const { brand } = useBrandContext();

  const {
    response: xLink,
    loading: xLinkLoading,
    error: xLinkError,
    reload: xLinkReload,
  } = useXLink({ brandId: brand?.id });

  const [xDisconnect] = useXDisconnect({ brandId: brand?.id ?? "" });

  const {
    response: xStatus,
    error: xStatusError,
    reload: xStatusReload,
  } = useXStatus({
    brandId: brand?.id,
  });

  const {
    response: linkedinLink,
    loading: linkedinLinkLoading,
    error: linkedinLinkError,
    reload: linkedinLinkReload,
  } = useLinkedinLink({
    brandId: brand?.id,
  });

  const [linkedinDisconnect] = useLinkedinDisconnect({
    brandId: brand?.id ?? "",
  });

  const {
    response: linkedinStatus,
    error: linkedinStatusError,
    reload: linkedinStatusReload,
  } = useLinkedinStatus({
    brandId: brand?.id,
  });

  useEffect(() => {
    if (brand) {
      xLinkReload();
      linkedinLinkReload();
    }
  }, [brand, xLinkReload, linkedinLinkReload]);

  const { error: brandsLoadingError } = useGetBrandsQuery();

  return (
    <>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Integrations</h1>
      </Flex>

      {(brandsLoadingError ||
        xLinkError ||
        xStatusError ||
        linkedinLinkError ||
        linkedinStatusError) && (
        <Flex pb="3">
          <Alert variant="destructive">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {(brandsLoadingError && "message" in brandsLoadingError
                ? brandsLoadingError.message
                : "") ||
                (xLinkError && "message" in xLinkError
                  ? xLinkError.message
                  : "") ||
                (xStatusError && "message" in xStatusError
                  ? xStatusError.message
                  : "") ||
                (linkedinLinkError && "message" in linkedinLinkError
                  ? linkedinLinkError.message
                  : "") ||
                (linkedinStatusError && "message" in linkedinStatusError
                  ? linkedinStatusError.message
                  : "")}
            </AlertDescription>
          </Alert>
        </Flex>
      )}

      <Flex gap="4">
        <Integration
          imgSrc="/x.png"
          imgAlt="X Logo"
          description="Connect your brand's X account to automatically publish X posts."
          onConnect={() => {
            window.location.href = xLink!;
          }}
          enabled={
            (xLink !== null || xLinkLoading) &&
            xStatus?.status === "not_connected"
          }
          connected={xStatus?.status === "connected"}
          onDisconnect={async () => {
            await xDisconnect();
            xStatusReload();
          }}
        />
        <Integration
          imgSrc="/linkedin.png"
          imgAlt="Linkedin Logo"
          description="Connect your brand's Linkedin account to automatically publish Linkedin posts."
          onConnect={() => {
            window.location.href = linkedinLink!;
          }}
          enabled={
            (linkedinLink !== null || linkedinLinkLoading) &&
            linkedinStatus?.status === "not_connected"
          }
          connected={linkedinStatus?.status === "connected"}
          onDisconnect={async () => {
            await linkedinDisconnect();
            linkedinStatusReload();
          }}
        />
      </Flex>
    </>
  );
};
