import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "@/hooks/use-auth";
import { decodeJwt } from "jose";
import { AppSidebar } from "@/components/sidebar-v2";
import { SidebarTrigger, useSidebar } from "@/components/ui/sidebar";
import { OnboardingContainer } from "@/containers/onboarding";
import { cn } from "@/lib/utils";

export const AppLayout = (): JSX.Element => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { state } = useSidebar();

  useEffect(() => {
    try {
      if (!token) {
        navigate("/auth/login");
      }

      const { exp } = decodeJwt(token!);

      if (!exp) {
        navigate("/auth/login");
      }

      if (exp! * 1000 < new Date().getTime()) {
        navigate("/auth/login");
      }
    } catch (error) {
      navigate("/auth/login");
    }
  }, [token, navigate]);

  return (
    <div
      className={cn(
        "grid min-h-screen w-full",
        state === "expanded"
          ? "md:grid-cols-[16rem_1fr]"
          : "md:grid-cols-[3rem_1fr]",
        "transition-all duration-300"
      )}
    >
      <AppSidebar
        className={cn(
          state === "expanded" ? "w-64" : "w-12",
          "transition-all duration-300"
        )}
      />

      <div className="flex flex-col h-screen">
        <main className="flex-1 overflow-auto p-4 lg:p-6">
          <div className="max-w-7xl mx-auto pt-12 pb-16">
            <SidebarTrigger className="md:hidden" />
            <Outlet />
            <OnboardingContainer />
          </div>
        </main>
      </div>
    </div>
  );
};
