import axios from "axios";

import { useAuth } from "../use-auth";
import { useState } from "react";

export const useLinkedinDisconnect = ({
  brandId,
}: {
  brandId: string;
}): [() => Promise<void>, { error: Error | null; loading: boolean }] => {
  const { token } = useAuth();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const disconnect = async () => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${
        import.meta.env.VITE_API_URL
      }/brands/${brandId}/linkedin/disconnect`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return [disconnect, { error, loading }];
};
