"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format, formatRelative } from "date-fns";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Flex } from "@radix-ui/themes";
import { TypographyH4, TypographyP } from "@/components";
import { useEffect, useState } from "react";
import { useXStatus } from "@/hooks/use-x-status";
import { useLinkedinPages } from "@/hooks/use-linkedin-pages";
import { useLinkedinStatus } from "@/hooks/use-linkedin-status";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useApproveContentMutation, useCancelApprovalMutation } from "@/redux";
import { useNotificationsContext } from "@/hooks";

export interface ApproveContainerProps {
  brandId?: string;
  contentId?: string;
  contentType?: string;
  contentStatus?: string;
  isApproved?: boolean;
  publishDate?: Date;
}

const FormSchema = z.object({
  publishDate: z.date({
    required_error: "A publish date is required.",
  }),
  linkedinOrganisationId: z.string().optional(),
});

export const ApproveContainer = ({
  brandId,
  contentId,
  contentType,
  contentStatus,
  isApproved,
  publishDate,
}: ApproveContainerProps): JSX.Element => {
  const [approving, setApproving] = useState(false);
  const { response: linkedinPages, reload: loadLinkedinPages } =
    useLinkedinPages({ brandId });
  const { response: linkedinStatus, loading: linkedinStatusLoading } =
    useLinkedinStatus({ brandId });
  const { response: xStatus, loading: xStatusLoading } = useXStatus({
    brandId,
  });
  const [approveContent, { error: approveError }] = useApproveContentMutation();
  const [cancelApproval, { error: cancelApprovalError }] =
    useCancelApprovalMutation();
  const { addNotification } = useNotificationsContext();

  useEffect(() => {
    if (!contentType) return;
    if (!linkedinStatus) return;
    if (linkedinStatus?.status === "not_connected") return;
    if (linkedinPages) return;
    if (contentType !== "linkedin_post") return;
    loadLinkedinPages();
  }, [contentType, linkedinStatus, loadLinkedinPages, linkedinPages]);

  const isDisabled =
    approving ||
    contentStatus !== "draft" ||
    linkedinStatusLoading ||
    xStatusLoading ||
    (contentType === "linkedin_post" &&
      linkedinStatus?.status === "not_connected") ||
    (contentType === "x_post" && xStatus?.status === "not_connected");

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      publishDate: publishDate ? new Date(publishDate) : undefined,
      linkedinOrganisationId: undefined,
    },
  });

  useEffect(() => {
    if (approveError) {
      if (approveError instanceof Error) {
        addNotification({ message: approveError.message });
      } else {
        addNotification({ message: "Something went wrong. Please try again." });
      }
    }
  }, [approveError, addNotification]);

  useEffect(() => {
    if (cancelApprovalError) {
      if (cancelApprovalError instanceof Error) {
        addNotification({ message: cancelApprovalError.message });
      } else {
        addNotification({ message: "Something went wrong. Please try again." });
      }
    }
  }, [cancelApprovalError, addNotification]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    publish({
      brandId: brandId!,
      contentId: contentId!,
      publishDate: data.publishDate,
      linkedinOrganisationId: data.linkedinOrganisationId,
    });
  }

  const publish = ({
    brandId,
    contentId,
    publishDate,
    linkedinOrganisationId,
  }: {
    brandId: string;
    contentId: string;
    publishDate: Date;
    linkedinOrganisationId?: string;
  }) => {
    if (isDisabled) return;
    if (!contentId) return;
    if (!brandId) return;
    if (!publishDate) return;
    setApproving(true);

    try {
      approveContent({
        brandId,
        contentId,
        publishDate,
        linkedinOrganisationId,
      });
    } catch (error) {
      if (error instanceof Error) {
        addNotification({
          message: error.message,
        });
      } else {
        addNotification({
          message: "Something went wrong. Please try again.",
        });
      }
    } finally {
      setApproving(false);
    }
  };

  return (
    <Flex direction="column" gap="2" width="100%" pt="12px">
      <TypographyH4>Approve and Schedule Publishing</TypographyH4>

      {!isApproved && contentStatus !== "final" && (
        <>
          <TypographyP className="pt-0">
            Approve the content and schedule it for publishing.
          </TypographyP>

          <Flex justify="between" width="100%" gap="12px">
            <Flex width="100%">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-8 w-full"
                >
                  <Flex direction="column" gap="12px">
                    <FormField
                      control={form.control}
                      name="publishDate"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <Popover>
                            <PopoverTrigger asChild disabled={isDisabled}>
                              <FormControl>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "w-auto pl-3 text-left font-normal",
                                    !field.value && "text-muted-foreground"
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, "PPP 'at' h:mm a")
                                  ) : (
                                    <span>
                                      Select the publish date and time
                                    </span>
                                  )}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-auto p-4"
                              align="start"
                            >
                              <div className="space-y-4">
                                <Calendar
                                  mode="single"
                                  selected={field.value}
                                  onSelect={(date) => {
                                    if (date) {
                                      // Preserve the current time when changing date
                                      const currentTime =
                                        field.value || undefined;
                                      date.setHours(
                                        currentTime?.getHours() || 9
                                      );
                                      date.setMinutes(
                                        currentTime?.getMinutes() || 0
                                      );
                                      field.onChange(date);
                                    }
                                  }}
                                  disabled={(date) => date < new Date()}
                                  initialFocus
                                />
                                <div className="flex gap-2 items-center justify-center">
                                  <Select
                                    onValueChange={(value) => {
                                      const date = field.value || new Date();
                                      date.setHours(parseInt(value));
                                      field.onChange(new Date(date));
                                    }}
                                    defaultValue={
                                      field.value?.getHours().toString() || "9"
                                    }
                                  >
                                    <SelectTrigger className="w-24">
                                      <SelectValue placeholder="Hour" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Array.from({ length: 24 }, (_, i) => (
                                        <SelectItem
                                          key={i}
                                          value={i.toString()}
                                        >
                                          {i.toString().padStart(2, "0")}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  :
                                  <Select
                                    onValueChange={(value) => {
                                      const date = field.value || new Date();
                                      date.setMinutes(parseInt(value));
                                      field.onChange(new Date(date));
                                    }}
                                    defaultValue={
                                      field.value?.getMinutes().toString() ||
                                      "0"
                                    }
                                  >
                                    <SelectTrigger className="w-24">
                                      <SelectValue placeholder="Minute" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Array.from(
                                        { length: 12 },
                                        (_, i) => i * 5
                                      ).map((minute) => (
                                        <SelectItem
                                          key={minute}
                                          value={minute.toString()}
                                        >
                                          {minute.toString().padStart(2, "0")}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                </div>
                              </div>
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {contentType === "linkedin_post" && (
                      <FormField
                        control={form.control}
                        name="linkedinOrganisationId"
                        render={({ field }) => (
                          <FormItem>
                            <Select
                              onValueChange={field.onChange}
                              disabled={isDisabled}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select LinkedIn Page" />
                              </SelectTrigger>
                              <SelectContent>
                                {linkedinPages &&
                                  linkedinPages.pages.map((page) => (
                                    <SelectItem value={page.id}>
                                      {page.localizedName}
                                    </SelectItem>
                                  ))}
                                <SelectItem value="self">
                                  Your personal profile
                                </SelectItem>
                              </SelectContent>
                            </Select>
                          </FormItem>
                        )}
                      />
                    )}
                    {contentType === "linkedin_post" && (
                      <Button
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={isDisabled}
                      >
                        Approve
                      </Button>
                    )}
                  </Flex>
                </form>
              </Form>
            </Flex>

            {contentType !== "linkedin_post" && (
              <Button
                onClick={form.handleSubmit(onSubmit)}
                disabled={isDisabled}
              >
                Approve
              </Button>
            )}
          </Flex>
        </>
      )}

      {isApproved && contentStatus !== "final" && (
        <>
          <TypographyP className="pt-0">
            The content has been approved and scheduled for publishing on{" "}
            <strong>
              {publishDate ? format(publishDate, "PPP 'at' h:mm a") : "N/A"}
            </strong>
            .
          </TypographyP>

          <Flex gap="12px">
            <Button
              onClick={() =>
                publish({
                  brandId: brandId!,
                  contentId: contentId!,
                  publishDate: new Date(),
                })
              }
            >
              Publish Now
            </Button>
            <Button
              variant="outline"
              onClick={() =>
                cancelApproval({ brandId: brandId!, contentId: contentId! })
              }
            >
              Cancel Publishing
            </Button>
          </Flex>
        </>
      )}

      {contentStatus === "final" && (
        <TypographyP className="pt-0">
          The content was published{" "}
          <strong>
            {publishDate ? formatRelative(publishDate, new Date()) : "N/A"}
          </strong>
        </TypographyP>
      )}
    </Flex>
  );
};
