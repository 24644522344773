import { Flex } from "@radix-ui/themes";
import { Card } from "@/components/ui/card";
import { TypographyP } from "@/components/typography";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";

export interface IntegrationProps {
  imgSrc: string;
  imgAlt: string;
  description: string;
  enabled: boolean;
  connected: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
}

export const Integration = ({
  imgSrc,
  imgAlt,
  description,
  enabled = true,
  connected = false,
  onConnect,
  onDisconnect,
}: IntegrationProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Card>
      <Flex p="4" direction="column" gap="4" width="400px">
        <Flex direction="column" align="center" justify="center">
          <img src={imgSrc} alt={imgAlt} width={100} className="p-2" />
        </Flex>

        <TypographyP className="pt-0">{description}</TypographyP>

        {!connected ? (
          <Button disabled={!enabled} onClick={onConnect}>
            Connect
          </Button>
        ) : (
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline">Disconnect</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogDescription>
                  This will disconnect your account. You can always reconnect it
                  later.
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-between">
                <Button variant="outline" onClick={() => setDialogOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={onDisconnect}>Disconnect</Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </Flex>
    </Card>
  );
};
